<template>
	<b-card>
		<b-row class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>Asset Type</b>
			</b-col>
			<b-col>{{ row.item.assetType }}</b-col>
		</b-row>
		<b-row class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>Asset Type Code</b>
			</b-col>
			<b-col>{{ row.item.assetTypeCode }}</b-col>
		</b-row>
		<b-row class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>Stickers</b>
			</b-col>
			<b-col class="numFont">{{ stickerRange.first }} - {{ stickerRange.last }}</b-col>
		</b-row>
		<b-row class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>Total # of Stickers</b>
			</b-col>
			<b-col class="numFont">{{ row.item.quantity }}</b-col>
		</b-row>
		<b-row class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>Date Generated</b>
			</b-col>
			<b-col>{{ dateGenerated }}</b-col>
		</b-row>
		<b-row class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>Status</b>
			</b-col>
			<b-col>{{ row.item.status }}</b-col>
		</b-row>
		<b-row class="mb-2" v-if="row.item.reason">
			<b-col sm="3" class="text-sm-right">
				<b>Reason</b>
			</b-col>
			<b-col>{{ row.item.reason }}</b-col>
		</b-row>
		<b-row v-if="stickers.newStickers.length !== 0 || stickers.reprintStickers.length !== 0" class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>New</b>
			</b-col>
			<b-col class="numFont">
				{{ stickers.newStickers.length }}
				<b-button size="sm" variant="success" v-b-tooltip.hover.top="'View Details'" class="mr-1"
					@click="isNewStickerToggle = !isNewStickerToggle">
					<i class="fa fa-eye"></i>
				</b-button>
				<b-card-body v-if="isNewStickerToggle">
					<template v-if="stickers.newStickers.length > 0">
						<b-badge class="mr-2" variant="primary" v-for="item in stickers.newStickers" :key="item">{{ item
						}}</b-badge>
					</template>
					<i v-else>No New stickers generated.</i>
				</b-card-body>
			</b-col>
		</b-row>
		<b-row v-if="stickers.reprintStickers.length !== 0 || stickers.newStickers.length !== 0" class="mb-2">
			<b-col sm="3" class="text-sm-right">
				<b>Reprint</b>
			</b-col>
			<b-col class="numFont">
				{{ stickers.reprintStickers.length }}
				<b-button size="sm" variant="success" v-b-tooltip.hover.top="'View Details'" class="mr-1"
					@click="isReprintStickerToggle = !isReprintStickerToggle">
					<i class="fa fa-eye"></i>
				</b-button>
				<b-card-body v-if="isReprintStickerToggle">
					<template v-if="stickers.reprintStickers.length > 0">
						<b-badge class="mr-2" variant="primary" v-for="item in stickers.reprintStickers" :key="item">{{ item
						}}</b-badge>
					</template>
					<i v-else>No Reprint stickers generated.</i>
				</b-card-body>
			</b-col>
		</b-row>
		<b-row class="mt-4">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

export default {
	name: 'StickerGeneratorDetailsView',
	data() {
		return {
			isNewStickerToggle: false,
			isReprintStickerToggle: false
		};
	},
	props: {
		row: {
			type: Object,
			required: true
		}
	},
	computed: {
		dateGenerated() {
			return DateUtil.getFormattedDateWithTime(this.row.item.dateGenerated);
		},
		stickerRange() {
			return !this.row.item.stickers
				? []
				: {
					first: this.row.item.stickers[0],
					last: this.row.item.stickers.slice(-1)[0]
				};
		},
		stickers() {
			return {
				newStickers: this.row.item.hasOwnProperty('stickerCategories')
					? this.row.item.stickerCategories.newStickers
					: [],
				reprintStickers: this.row.item.hasOwnProperty('stickerCategories')
					? this.row.item.stickerCategories.reprintStickers
					: []
			};
		}
	}
};
</script>

<style scoped>
table tr td {
	padding: 8px 0px;
	border: none;
}

.borderless td,
.borderless th {
	border: none;
}
</style>
