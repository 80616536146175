import { DateUtil } from '@/utils/dateutil';


export const assets = (params) => {
  return {
    assetId: '',
    assetCode: '',
    assetType: params.assetType.name,
    assetTypeId: params.assetType.id,
    assetTypeCode: params.assetTypeCode.codeName,
    assetTypeCodeId: params.assetTypeCode.id,
    origin: params.company.name,
    originId: params.company.id,
    stickerCopies: params.noOfStickers,
    status: params.status,
    currCompany: params.company.name,
    currCompanyId: params.company.id,
    currentLocation: {},
    nextLocation: null,
    dateCreated: DateUtil.getCurrentTimestamp(),
    createdBy: params.currUser,
    dateActivated: null,
    activatedBy: null,
    dateDeactivated: null,
    deactivatedBy: null
  }
}