<template>
	<b-modal id="generate-stickers" size="lg" @close="onReset" no-close-on-backdrop>
		<template v-slot:modal-title>Generate Sticker</template>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="onSubmit" @reset="onReset" novalidate>
			<div class="row">
				<div class="col-md-6">
					<b-form-group label="Company">
						<b-form-select name="Company" v-model="form.company" :options="companyOptions"
							v-validate="'required'" />
						<span v-show="errors.has('Company')" class="help-block">
							{{ errors.first('Company') }}
						</span>
					</b-form-group>

					<b-form-group label="Asset Type">
						<b-form-select name="Asset Type" v-model="form.assetType" :options="assetTypeOptions"
							v-validate="'required'" />
						<span v-show="errors.has('Asset Type')" class="help-block">
							{{ errors.first('Asset Type') }}
						</span>
					</b-form-group>

					<b-form-group label="Number of Uniform Stickers">
						<b-form-input name="Number of Uniform Stickers" type="number" v-model="form.noOfStickers" min="1"
							max="10" v-validate="'required|numeric|min_value:1|max_value:10'" />
						<span v-show="errors.has('nNumber of Uniform Stickers')" class="help-block">{{
							errors.first('Number of Uniform Stickers') }}
						</span>
					</b-form-group>

					<b-form-group label="Asset Type Code">
						<b-form-select name="Asset Type Code" v-model="form.assetTypeCode" :options="assetTypeCodeOptions"
							v-validate="'required'" />
						<span v-show="errors.has('Asset Type Code')" class="help-block">
							{{ errors.first('Asset Type Code') }}
						</span>
					</b-form-group>
				</div>
				<div class="col-md-6">
					<b-form-group label="Sticker Prefix">
						<b-form-input :value="form.assetTypeCode ? form.assetTypeCode.prefix : null" readonly />
					</b-form-group>

					<b-form-group label="Sticker Setup">
						<b-form-select name="Sticker Setup" v-model="form.selectedStickerSetup" :options="stickerSetups"
							v-validate="'required'" />
						<span v-show="errors.has('Sticker Setup')" class="help-block">
							{{ errors.first('Sticker Setup') }}
						</span>
					</b-form-group>

					<div v-if="form.selectedStickerSetup">
						<b-form-group label="Start Value">
							<b-form-input name="Start Value" v-model="startVal" placeholder="(6) digit code without prefix."
								v-validate="'required|alpha_num|ayun_stickers|min:6|max:6'" maxlength="6" />
							<span v-show="errors.has('Start Value')" class="help-block">
								{{ errors.first('Start Value') }}
							</span>
						</b-form-group>

						<b-form-group label="End Value" v-show="form.selectedStickerSetup === 'range'">
							<b-form-input name="End Value" v-model="endVal" placeholder="(6) digit code without prefix."
								v-validate="{
									required: endValIsRequired,
									regex: /^[0-9A-Za-z]{6}$/
								}" maxlength="6" />
							<span v-show="errors.has('End Value')" class="help-block">
								{{ errors.first('End Value') }}
							</span>
							<span v-show="withSetupError.status" class="help-block">{{
								withSetupError.message
							}}</span>
						</b-form-group>

						<b-form-group label="Quantity" v-show="form.selectedStickerSetup === 'count'">
							<b-form-input name="Quantity" v-model="form.stickerParams.count" v-validate="{
								required: quantityIsRequired,
								regex: /^[0-9]{1,4}$/,
								min_value: 0,
								max_value: 5000,
							}" />
							<span v-show="errors.has('Quantity')" class="help-block">
								{{ errors.first('Quantity') }}
							</span>
							<span v-show="withSetupError.status" class="help-block">{{
								withSetupError.message
							}}</span>
						</b-form-group>
					</div>

					<b-button class="mb-4" type="submit" variant="primary"
						:disabled="getValidationRequestStatus || withSetupError.status" v-show="form.selectedStickerSetup">
						Generate
					</b-button>
				</div>
			</div>
			<div class="row" v-if="loadConfirmationStickerParams">
				<div class="col-md-12">
					<ConfirmStickerParams :item="validationResult" :reason="form.reason" @onInputReason="onInputReason" />
				</div>
			</div>
		</b-form>
		<template v-slot:modal-footer>
			<b-button @click="onReset">Cancel</b-button>
			<b-button variant="primary" @click="onProceed" :disabled="!isValidToProceed()">Proceed</b-button>
		</template>
	</b-modal>
</template>

<script>
// Component
import ConfirmStickerParams from './ConfirmStickerParams';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import * as form from '@/utils/formCollectionUtil';
import { mapGetters } from 'vuex';
import _ from 'lodash';

import { Validator } from 'vee-validate';
Validator.extend('ayun_stickers', {
	getMessage: (field) => 'The ' + field + ' must not contain letters i, l, o.',
	validate: (value) => {
		return !(value.includes('i') || value.includes('l') || value.includes('o'));
	},
});

export default {
	name: 'GenerateStickerDialog',
	components: {
		Loading,
		ConfirmStickerParams
	},
	data() {
		return {
			isLoading: false,
			form: {
				company: { ...config.companyDefaultValue },
				assetType: null,
				assetTypeCode: null,
				uom: '',
				unitCost: 0,
				selectedStickerSetup: '',
				stickerParams: {
					startVal: '',
					endVal: '',
					count: 0,
				},
				status: 'Inactive',
				noOfStickers: 1,
				assetCount: 0,
				assets: [],
				reason: '',
			},

			companyOptions: [],
			assetTypeOptions: [],
			assetTypeCodeOptions: [],
			stickerSetups: [
				{ value: 'range', text: 'By Range' },
				{ value: 'count', text: 'By Count' },
			],

			allCompaniesObj: {},
			allAssetTypesObj: {},
			allAssetTypeCodesObj: {},

			loadConfirmationStickerParams: false,
			validationResult: {},
			withSetupError: {
				status: false,
				message: '',
			},
		};
	},
	watch: {
		'form.company'(v) {
			this.resetDefaultSelection('assetType');
			this.resetDefaultSelection('assetTypeCode');

			if (v) {
				const originId = v.id;
				this.fillAssetTypeOptions(originId);
				this.fillAssetTypeCodeOptions(originId, '');
			}
		},
		'form.assetType'(v) {
			this.resetDefaultSelection('assetTypeCode');
			if (v) {
				const assetTypeId = v.id;
				this.form.noOfStickers = parseInt(v.noOfStickers);
				this.form.uom = v.uom;
				this.form.unitCost = v.unitCost;

				this.fillAssetTypeCodeOptions('', assetTypeId);
			}
		},
		'form.selectedStickerSetup'() {
			this.clearStickerFields();
			this.resetValidation();
		},
		'form.stickerParams.startVal'() {
			this.refreshStickerFieldErrors();
		},
		'form.stickerParams.endVal'() {
			this.refreshStickerFieldErrors();
		},
	},
	computed: {
		...mapGetters('stickerGenerator', [
			'getValidationRequestStatus',
			'getGenerateRequestStatus',
		]),
		endValIsRequired() {
			return this.form.selectedStickerSetup === 'range';
		},
		quantityIsRequired() {
			return this.form.selectedStickerSetup === 'count';
		},
		startVal: {
			get() {
				return this.form.stickerParams.startVal;
			},
			set(v) {
				this.form.stickerParams.startVal = v.toLowerCase();
			},
		},
		endVal: {
			get() {
				return this.form.stickerParams.endVal;
			},
			set(v) {
				this.form.stickerParams.endVal = v.toLowerCase();
			},
		},
		hasErrors() {
			return _.filter(this.$validator.errors.items, item => item.field !== null);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allAssetTypeCodesObj = { ...this.$store.getters.assetTypeCodes };

				this.fillCompanyOptions();
				this.resetDefaultSelection('assetType');
				this.resetDefaultSelection('assetTypeCode');

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		resetDefaultSelection(option) {
			if (option === 'assetType') {
				this.assetTypeOptions = [{ value: null, text: '- Please select - ' }];
			} else if (option === 'assetTypeCode') {
				this.assetTypeCodeOptions = [{ value: null, text: '- Please select - ' }];
			}

			this.form[option] = null;
		},
		fillCompanyOptions() {
			this.companyOptions = DropDownItemsUtil.retrieveActiveCompanies(this.allCompaniesObj);
		},
		fillAssetTypeOptions(companyId) {
			const filteredObj = _.filter(this.allAssetTypesObj, o => {
				return o.originId === companyId;
			});

			if (!_.isEmpty(filteredObj)) {
				this.assetTypeOptions = DropDownItemsUtil.retrieveStickerAssetTypes(filteredObj);
			} else {
				this.assetTypeOptions = [{ value: null, text: '- Please select - ' }];
			}
		},
		fillAssetTypeCodeOptions(companyId, assetTypeId) {
			const filteredObj = _.filter(this.allAssetTypeCodesObj, o => {
				return o.originId === companyId || o.assetTypeId === assetTypeId;
			});

			if (!_.isEmpty(filteredObj)) {
				this.assetTypeCodeOptions = DropDownItemsUtil.retrieveAssetTypeCodes(filteredObj);
			} else {
				this.assetTypeCodeOptions = [{ value: null, text: '- Please select - ' }];
			}
		},
		onInputReason(v) {
			this.form.reason = v;
		},
		async onSubmit() {
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid || this.validateStickerFields()) {
				this.isLoading = false;
				return;
			}

			try {
				this.loadConfirmationStickerParams = false;
				const type = this.form.selectedStickerSetup;
				const stickerDetails = {
					prefix: this.form.assetTypeCode.prefix,
					brand: this.form.assetType.brand,
					contactNo: this.form.company.contactNo,
					assetTypeId: this.form.assetType.id,
					assetTypeCodeId: this.form.assetTypeCode.id,
				};

				if (type == 'range') {
					stickerDetails.startVal = this.startVal;
					stickerDetails.endVal = this.endVal;
				} else if (type == 'count') {
					stickerDetails.startVal = this.startVal;
					stickerDetails.count = this.form.stickerParams.count;
				} else return this.$toaster.error('Please select sticker setup!');

				const companyDetails = form.assets({
					currUser: this.$store.getters.email,
					...this.form,
				});

				companyDetails.uom = this.form.uom;
				companyDetails.unitCost = parseFloat(this.form.unitCost);
				companyDetails.stickerCopies = parseInt(this.form.noOfStickers);
				companyDetails.sessionId = 'SG' + DateUtil.getCurrentTimestamp();

				let result = await this.$store.dispatch('stickerGenerator/stickerValidation', {
					stickerDetails,
					type,
				});

				this.$store.commit('stickerGenerator/FILL_STICKER_DETAILS', stickerDetails);
				this.$store.commit('stickerGenerator/FILL_COMPANY_DETAILS', companyDetails);
				this.validationResult = result;

				const stickers = [
					...result.stickers.new,
					...result.stickers.reprint,
				].sort().length;

				if (this.isQuantityExceeded(stickers, 5000)) {
					this.withSetupError = {
						status: true,
						message: 'Quantity must be 5000 or less',
					};
				} else {
					this.loadConfirmationStickerParams = true;
				}

			} catch (_error) {
				this.$toaster.error('Request timeout. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async onProceed() {
			// show loading indicator
			this.isLoading = true;

			try {
				await this.$store.dispatch('stickerGenerator/generateStickers', {
					reason: this.form.reason,
				});

				this.onReset();
				this.$toaster.success(this.$store.state.stickerGenerator.generate_success);
				this.$emit('onGenerateSuccess');

			} catch (_error) {
				this.$toaster.warning('Error generating stickers. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.$bvModal.hide('generate-stickers');
			this.form.company = { ...config.companyDefaultValue };

			this.fillCompanyOptions();
			this.resetDefaultSelection('assetType');
			this.resetDefaultSelection('assetTypeCode');
			this.clearStickerFields();
			this.loadConfirmationStickerParams = false;
			this.form.selectedStickerSetup = '';
			this.form.noOfStickers = 1;
			this.form.reason = '';
			this.form.uom = '';
			this.form.unitCost = 0;

			// Reset validation
			this.isLoading = false;
			this.resetValidation();
		},
		resetValidation() {
			this.$validator.reset();
			this.errors.clear();
		},
		validateStickerFields() {
			const validation = { status: false, message: '' };
			let type = this.form.selectedStickerSetup;
			const { startVal, endVal } = {
				startVal: this.startVal,
				endVal: this.endVal,
				count: this.form.stickerParams.count,
			};
			if (type == 'range') {
				if (startVal == endVal) {
					validation.status = true;
					validation.message = 'Start and End value must not be equal.';
				} else if (startVal > endVal) {
					validation.status = true;
					validation.message = 'End value must not be less than start value.';
				}
			}

			this.withSetupError = {
				status: validation.status,
				message: validation.message,
			};
			this.loadConfirmationStickerParams = false;
			return validation.status;
		},
		clearStickerFields() {
			this.form.stickerParams = {
				startVal: '',
				endVal: '',
				count: 0,
			};
		},
		refreshStickerFieldErrors() {
			this.withSetupError = {
				status: false,
				message: '',
			};
		},
		isQuantityExceeded(quantity, limit) {
			return parseInt(quantity) > limit ? true : false;
		},
		isValidToProceed() {
			return !this.isLoading
				&& this.loadConfirmationStickerParams
				&& ((this.validationResult.stickers.reprint.length > 0 && this.form.reason)
					|| this.validationResult.stickers.reprint.length == 0);
		},
	},
};
</script>
